// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1000px) {
  .top-links {
    top: 10px;
  }
}

h1 {
  margin: 0px 0px 30px 0px;
  font-weight: bold;
  font-size: 26px;
  color: #555;
}

h3 {
  margin: 0px 0px 20px 0px;
  font-weight: bold;
  font-size: 22px;
  color: #555;
}

h4 {
  margin: 0px 0px 20px 0px;
  font-weight: bold;
  font-size: 18px;
  color: #555;
}

table {
  margin: 30px 0 30px 0;
}

table td {
  border: 1px solid #ccc;
  padding: 6px;
}

.content-article {
  margin: 30px 0px 0px 0px;
  color: #555;
  width: 900px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.container {
  padding-bottom: 50px; 
}

a {
  color: #03881c;
  text-decoration: none;
}

a:hover {
  color: #026c16;
}

.content-article p {
  margin: 0 0 20px;
}
`, "",{"version":3,"sources":["webpack://./css/main.css"],"names":[],"mappings":"AAAA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@media (max-width: 1000px) {\n  .top-links {\n    top: 10px;\n  }\n}\n\nh1 {\n  margin: 0px 0px 30px 0px;\n  font-weight: bold;\n  font-size: 26px;\n  color: #555;\n}\n\nh3 {\n  margin: 0px 0px 20px 0px;\n  font-weight: bold;\n  font-size: 22px;\n  color: #555;\n}\n\nh4 {\n  margin: 0px 0px 20px 0px;\n  font-weight: bold;\n  font-size: 18px;\n  color: #555;\n}\n\ntable {\n  margin: 30px 0 30px 0;\n}\n\ntable td {\n  border: 1px solid #ccc;\n  padding: 6px;\n}\n\n.content-article {\n  margin: 30px 0px 0px 0px;\n  color: #555;\n  width: 900px;\n  font-family: Arial, sans-serif;\n  font-size: 16px;\n  line-height: 20px;\n}\n\n.container {\n  padding-bottom: 50px; \n}\n\na {\n  color: #03881c;\n  text-decoration: none;\n}\n\na:hover {\n  color: #026c16;\n}\n\n.content-article p {\n  margin: 0 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
